import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "box", "trigger", "autoclose" ]
  static values = { url: String }

  connect () {
    if (!this.hasBoxTarget) {
      console.warn('no expandable box - box target')
      return
    }
    if (!this.hasTriggerTarget) {
      console.warn('no expandable box - trigger target')
    }
  }

  autocloseTargetConnected(target) {
    this.close()
  }

  close() {
    this.boxTarget.classList.remove('is-visible')
    this.boxTarget.style.overflow = 'hidden';
    this.boxTarget.style.maxHeight = '';
    this.triggerTarget.classList.remove('is-active')
    this.triggerTarget.classList.remove('is-visible')
  }
}