import Flatpickr from 'stimulus-flatpickr' 

// all flatpickr hooks are available as callbacks in your Stimulus controller
export default class extends Flatpickr {

  change(selectedDates, dateStr, instance) {
    if (selectedDates.length == 2) {
      this.element.closest("form").requestSubmit()
    }
  }

}