import '@hotwired/turbo';
import Rails from '@rails/ujs';
try {
  Rails.start();
} catch (e) {
  console.log(e);
}
import '@rails/activestorage'
import '@/javascripts/lib/modernizr-custom.min'
import '@/javascripts/main'
import '@/javascripts/sparkline'
import '@/javascripts/pyramid'
import '@/controllers/index.js';

function wasteTypesExpander($element) {
  let $el = $element;
  let $wasteList = $el.children('.biome__expanded-waste-types');
  let $trigger = $el.find('.waste-types-list-trigger');
  revealer($trigger, $wasteList);
}

function revealer($trigger, $module) {
  $trigger.on('click', function(event) {
    if($trigger.hasClass("is-active") && !$trigger.hasClass("is-visible")) {
      return;
    }

    $module.toggleClass('is-visible');
    $trigger.toggleClass('is-active');

    if($module.hasClass('is-visible')) {
      setTimeout(function() {
        $module.css('overflow', 'visible');
        $trigger.addClass("is-visible")
        $module.css('max-height', $module.height()); // to ensure the collapse animation starts at the right max-height
      }, 500);
    } else {
      $module.css('max-height', '');
      $trigger.removeClass("is-visible")
      $module.css('overflow', 'hidden');
    }
  });
}

function cssWidth($el) {
  return parseInt($el.css("width"));
}

function positionAThing($ref, $el) {
  let $refPos = $ref.position();
  let $refLeft = 0;
  if($refPos) {
    $refLeft = $refPos.left;
  }
  let $refHalf = cssWidth($ref) / 2;
  let $elHalf = cssWidth($el) / 2;
  $el.css('left', ($refLeft + $refHalf - $elHalf + 'px'));
}

function cancelClose($cancelButton) {
  let $parent = $cancelButton.closest('.biome__expandable-box');
  let $trigger = $parent.parents().find('.expandable-box__trigger');
  $parent.removeClass('is-visible').css('overflow','hidden');
  $parent.css('max-height', '');
  $trigger.removeClass('is-active').removeClass("is-visible");
}

$(document).on('change', '.js-filter-select-reset', function() {
  if($(this).val().length > 5) {
    let location = $(this).val();
    $(this).val('');
    window.location = location;
  }
})

$(document).on('change', '.js-filter-select', function() {
  if($(this).val().length > 5) {
    window.location = $(this).val();
  }
})

$.ajaxSetup({
  headers: {
    'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
  }
});

function initMatchHeight() {
  $('.match').matchHeight({ byRow: true });
}

function initTableSorter() {
  $(".tablesort").tablesorter({
    headerTemplate: "{content} {icon}",
    cssChildRow: "tablesorter-childRow"
  });
}

function initTableExpand() {
  $("body").on("click", ".table-expand a", function(e) {
    e.preventDefault();
    $("." + $(this).data("expand")).toggle();
  });
}

function scrollToHash() {
  const hash = window.location.hash;
  if(hash && hash.length > 5) {
    const aTag = $(hash);
    if(aTag.length > 0) {
      setTimeout(() => $('html,body').animate({scrollTop: aTag.offset().top}, 'fast'), 500);
    }
  }
}

function initUIInteractions() {
  $('.pattern__page-header').each(function() {
    let $el = $(this);
    let $box = $el.children('.biome__expandable-box') || $el.closest('.biome__expandable-box');
    let $boxArrow = $box.find('.expandable-box__arrow i');
    let $boxTrigger = $el.find('.expandable-box__trigger');
    let $boxTriggerIcon = $el.find('.expandable-box__trigger__icon');
    let $boxCancel = $el.find('.expandable-box__form button.expandable-close');
    let $download = $el.find('.popover__form button[type="submit"]');
    let $wasteList = $el.find('.biome__expanded-waste-types');
    let $listTrigger = $el.find('.waste-types-list-trigger');
    let $wasteListArrow = $wasteList.find('.expanded-waste-types__arrow i');

    if($wasteListArrow.length > 0) {
      positionAThing($listTrigger, $wasteListArrow);
    }

    if($boxArrow.length > 0) {
      positionAThing($boxTrigger, $boxArrow);
    }

    if($boxTriggerIcon.length > 0) {
      positionAThing($boxTriggerIcon, $boxArrow);
    }

    revealer($listTrigger, $wasteList);
    revealer($boxTrigger, $box);
    $boxCancel.on('click', function() {
      cancelClose($(this));
    });

    $download.on('click', function() {
      cancelClose($(this));
    });
  });


  $('.data-request, .indicators-list__indicator').each(function() {
    wasteTypesExpander($(this));
  });

  $('.dashboard-reports__peek').on('click', function() {
    $(this).toggleClass('is-active');
    $('.dashboard-reports').toggleClass('is-visible');
  });

  $('.search-group__name').click(function() {
    $(this).closest(".search-group").toggleClass("search-group--open")
  });

  $('.search-sub-group__name').click(function() {
    $(this).closest(".search-sub-group").toggleClass("search-sub-group--open")
  });
}

function initPopovers() {
  $('.popover').each(function() {
    let $pop = $(this);
    let $msg = $(this).find(".popover-message");
    $pop.webuiPopover({
      url: $msg,
      cache: false,
      trigger: "hover"
    })  });

  $('.js-popover').webuiPopover({
    cache: false,
    trigger: "hover"
  });

  $(".popover").first().webuiPopover('show');
}

function initFileInput() {
  $('.popover__input--file').each(function() {
		let $input = $(this),
			  $label = $input.next('.popover__input--file__label'),
			  labelVal = $label.html();

		$input.on('change', function(e) {
      console.log('change')
			let fileName = '';

			if(e.target.value) {
        fileName = e.target.value.split( '\\' ).pop();
      }

			if( fileName ) {
        $label.html('Chosen file: ' + fileName);
      } else {
        $label.html(labelVal);
      }
		});

		// Firefox bug fix
		$input
		.on( 'focus', function(){ $input.addClass( 'has-focus' ); })
		.on( 'blur', function(){ $input.removeClass( 'has-focus' ); });
	});

  // Reset file input HTML on cancelling form

  $('.popover__form button[type="reset"]').on('click', function() {
    let $fileInput = $(this).parent().prev().find('.popover__input--file__label');
    $fileInput.html('Select your file');
  });
}

function load() {
  initMatchHeight();
  initTableSorter();
  initTableExpand();
  scrollToHash();
  initUIInteractions();
  initPopovers();
  initFileInput();
}

$(document).on('focus','.form-input', function() {
  let $pop = $(this);
  let $msg = $(this).find(".popover-message");
  $pop.webuiPopover('destroy')
  if($pop.hasClass("popover")) {
    $pop.webuiPopover({
      url: $msg,
      cache: false,
      trigger: "hover"
    })
  }
});

document.addEventListener("turbo:load", function() {
  load();
})