import { Controller } from "@hotwired/stimulus"
import Noty from "noty";

export default class extends Controller {
  static values = { key: String, message: String }

  connect () {
    let key = this.keyValue

    if(key == "notice") {
      key = "information"
    } else if(key == "alert") {
      key = "warning"
    }

    new Noty({
      type: key,
      text: this.element.innerText,
      timeout: 5000,
      theme: "mint",
      closeWith: ['click', 'button']
    }).show()    
  }
}