import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    frame: String
  }

  change(e) {
    e.preventDefault()
    document.getElementById(this.frameValue).setAttribute("src", e.target.value)
  }

}