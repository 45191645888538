import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "input", "label" ]

  connect () {
    this.labelValue = this.labelTarget.innerHTML
    $(this.element).closest("form").on('reset', this.reset.bind(this))
  }

  reset() {
    this.labelTarget.innerHTML = this.labelValue;
  }

  change(event) {
    let fileName = '';

    if(event.target.value) {
      fileName = event.target.value.split( '\\' ).pop();
    }

    if( fileName ) {
      this.labelTarget.innerHTML = `Chosen file: ${fileName}`;
    } else {
      this.labelTarget.innerHTML = this.labelValue;
    }
  }
  
}








