Highcharts.Sparkline = function(a, b, c) {
  let defaultOptions, hasRenderToArg, options;
  hasRenderToArg = typeof a === 'string' || a.nodeName;
  options = b;
  defaultOptions = {
    chart: {
      renderTo: (options?.chart?.renderTo) || this,
      backgroundColor: null,
      borderWidth: 0,
      margin: [2, 0, 2, 0],
      width: 170,
      height: 30,
      style: {
        overflow: 'visible',
        fontFamily: 'modern-era'
      },
      skipClone: true
    },
    colors: ["#39A3EB"],
    lang: {
      thousandsSep: ','
    },
    title: {
      text: ''
    },
    credits: {
      enabled: false
    },
    xAxis: {
      visible: false,
      type: "datetime",
      labels: {
        enabled: false
      },
      title: {
        text: null
      }
    },
    yAxis: {
      visible: false
    },
    legend: {
      enabled: false
    },
    navigation: {
      buttonOptions: {
        enabled: false
      }
    },
    exporting: {
      chartOptions: {
        chart: {
          backgroundColor: "#FFFFFF"
        }
      }
    },
    plotOptions: {
      series: {
        dataLabels: {
          enabled: false
        },
        labels: {
          enabled: false
        }
      }
    },
    tooltip: {
      backgroundColor: "#ffffff",
      borderWidth: 1,
      shadow: false,
      useHTML: true,
      hideDelay: 0,
      shared: true,
      padding: 5,
      valueDecimals: 2,
      pointFormat: "<b>{point.y}</b>t",
      positioner: function(w, h, point) {
        return {
          x: point.plotX - w / 2,
          y: point.plotY - h - 10
        };
      },
      style: {
        fontFamily: 'modern-era-mono'
      }
    }
  };
  options = Highcharts.merge(defaultOptions, options);
  Highcharts.setOptions({
    lang: {
      thousandsSep: ','
    }
  });
  if (hasRenderToArg) {
    return new Highcharts.Chart(a, options, c);
  } else {
    return new Highcharts.Chart(options, b);
  }
};

document.addEventListener("turbo:load", function() {
  $(".sparkline").each(function() {
    let data;
    data = $(this).data('spark');
    $(this).highcharts('Sparkline', {
      series: [
        {
          data: data,
          color: "#3AA679",
          type: 'line',
          animation: false,
          lineWidth: 2,
          shadow: false,
          id: "primary",
          states: {
            hover: {
              lineWidth: 2
            }
          },
          marker: {
            radius: 1,
            states: {
              hover: {
                radius: 2
              }
            }
          }
        }
      ]
    });
  });

  $(".large-sparkline").each(function() {
    let data;
    data = $(this).data('spark');
    $(this).highcharts('Sparkline', {
      chart: {
        height: 140,
        width: 550,
        margin: [10, 0, 10, 0]
      },
      series: [
        {
          data: data,
          color: "#3AA679",
          type: 'line',
          animation: false,
          lineWidth: 2,
          shadow: false,
          id: "primary",
          states: {
            hover: {
              lineWidth: 2
            }
          },
          marker: {
            radius: 1,
            states: {
              hover: {
                radius: 2
              }
            }
          }
        }
      ]
    });
  });
})