/**
 * Template-specific JavaScript
 */
/** @namespace */
var template = template || {};

/** @constructor */
template = function($) {

  "use strict";

  /**
   * Default Settings
   * @type {obj}
   */
  const templateDefaults = {
    activeClass: 'is-active',
    noScroll: 'no-scroll',
    visibleClass: 'is-visible'
  };

  $(document).on("click", ".biome__popover__trigger", function(e) {
    if ($(e.target).closest(".biome__popover").length == 0) {
      // what is the current popover
      const $popover = $(this).find(".biome__popover")

      // loop over any open modals
      $('.biome__popover.is-visible').each(function() {
        // if it isn't the current one, hide it and remove
        // active class from parent to reset icon
        if (!$popover.is($(this))) {
          $(this).removeClass("is-visible");
          $(this).parent().removeClass("is-active");
        }
      })
      $(this).toggleClass(templateDefaults.activeClass);
      $popover.toggleClass(templateDefaults.visibleClass);
      e.stopPropagation();
    }
  });

  // Hide popover when the Cancel button is clicked in a popover form
  $(document).on("click", ".popover__form button[type='reset'], .popover__form button.button--download", function(e) {
    const $popoverParent = $(e.target).closest(".biome__popover");
    const $popoverParentTrigger = $popoverParent.closest('.biome__popover__trigger');
    $popoverParent.removeClass(templateDefaults.visibleClass);
    $popoverParentTrigger.removeClass(templateDefaults.activeClass);
  });

}(jQuery);
