import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "bucket", "list" ]
  static values = { parameter: String }

  modal(event) {
    document.querySelector("#sidebar-expanded").innerHTML = this.listTarget.innerHTML
    document.body.body.showExpandedSidebar(event)
    // document.body.classList.add("show-full-modal")
  }

  toggle(event) {
    event.preventDefault();
    if(!this.bucketTarget.classList.contains("disabled")) {
      this.bucketTarget.classList.toggle("active")

      // remove the parameter from the URL to prevent the bucket from re-opening on refresh 
      // if it is empty and was excplicityly closed
      // or if it is explicitly opened but not a value was chosen, leave it open on refresh
      let url = new URL(window.location.href)
      let value = url.searchParams.get(`search_${this.parameterValue}`)
      if (!this.bucketTarget.classList.contains("active") && !value) {
        url.searchParams.delete(`search_${this.parameterValue}`)
      }
      if (this.bucketTarget.classList.contains("active") && !value) {
        url.searchParams.set(`search_${this.parameterValue}`, "")
      }
      // Turbo.visit(url.toString(), { action: 'replace' })
      history.pushState({ turbo_frame_history: true }, "", url.toString())
    }
  }
}
