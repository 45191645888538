import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["frame"]
  static values = {
    url: String
  }

  click(e) {
    e.preventDefault()
    this.frameTarget.setAttribute("src", this.urlValue)
  }

}