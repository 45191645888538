
import Rails from "@rails/ujs"
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["form"]
  
  run(event) {
    Rails.fire(this.formTarget, "submit")
  }
  
  connect() {
    this.formTarget.addEventListener("submit", (e) => {
    })
  }

 }
