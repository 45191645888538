Highcharts.Pyramid = function(a, b, c) {
  let defaultOptions, hasRenderToArg, options;
  hasRenderToArg = typeof a === 'string' || a.nodeName;
  options = b;
  defaultOptions = {
    chart: {
      renderTo: (options?.chart?.renderTo) || this,
      type: 'pyramid',
      height: 200,
      width: 300,
      style: {
        fontFamily: 'modern-era'
      }
    },
    colors: ["#CCC", "#64E6A4", "#B8F0D4", "#FAD663", "#FA9092"],
    lang: {
      thousandsSep: ','
    },
    title: {
      text: ''
    },
    credits: {
      enabled: false
    },
    xAxis: {
      visible: false
    },
    yAxis: {
      visible: false
    },
    legend: {
      enabled: false
    },
    navigation: {
      buttonOptions: {
        enabled: false
      }
    },
    exporting: {
      chartOptions: {
        chart: {
          backgroundColor: "#FFFFFF"
        }
      }
    },
    plotOptions: {
      series: {
        dataLabels: {
          enabled: false
        }
      },
      pyramid: {
        reversed: false,
        label: {
          enabled: false
        }
      }
    },
    tooltip: {
      backgroundColor: "#ffffff",
      borderWidth: 1,
      shadow: false,
      useHTML: true,
      hideDelay: 0,
      shared: true,
      enabled: true,
      padding: 5,
      valueDecimals: 2,
      pointFormatter: function() {
        return "<b>" + (this.y.toFixed(2)) + "</b>t (" + (this.percentage.toFixed(2)) + "%)";
      },
      style: {
        fontFamily: 'modern-era-mono'
      }
    }
  };
  options = Highcharts.merge(defaultOptions, options);
  Highcharts.setOptions({
    lang: {
      thousandsSep: ','
    }
  });
  if (hasRenderToArg) {
    return new Highcharts.Chart(a, options, c);
  } else {
    return new Highcharts.Chart(options, b);
  }
};

document.addEventListener("turbo:load", function() {
  $(".pyramid").each(function() {
    let data;
    data = $(this).data('pyramid');
    $(this).highcharts('Pyramid', {
      series: [
        {
          data: data
        }
      ]
    });
  });
})