import { Controller } from "@hotwired/stimulus"
import TomSelect from 'tom-select'

export default class extends Controller {

  static values = {
    remove: Boolean
  }

  options() {
    return {}
  }

  connect() {
    const select = this.element.querySelector("select")
    const multiple = select.multiple
    const remove = this.removeValue || multiple
    const placeholder = select.getAttribute("placeholder") || "Select..."

    const plugins = ['dropdown_input']
    // const plugins = []
    if(remove && multiple) {
      plugins.push('remove_button')
    } else if(remove) {
      plugins.push('clear_button')
    }

    const defaultOptions = {
      placeholder: placeholder,
      hidePlaceholder: true,
      maxItems: multiple ? null : 1,
      plugins: plugins,
      maxOptions: null,
      onItemRemove: remove && multiple ? null : function(values) {
        this.input.value = ""
      }
    }

    const selectOptions = Object.assign(defaultOptions, this.options())
    const selectize = new TomSelect(select, selectOptions)
  }

}
