import { Application } from "@hotwired/stimulus"

import search from "./search_controller"
import bucket from "./bucket_controller"
import core_search from "./core_search_controller"
import turbo_frame_history from "./turbo_frame_history_controller"
import search_select from "./search_select_controller"
import selectize from "./tom_select_controller"
import redirect from "./redirect_controller"
import expandable_box from "./expandable_box_controller"
import flash from "./flash_controller"
import date_picker_range from "./date_picker_range_controller"
import file_input from "./file_input_controller"

window.Stimulus = Application.start()

// Stimulus.handleError = (error, message, detail) => {
//   console.warn(message, detail)
//   // ErrorTrackingSystem.captureException(error)
// }

Stimulus.register("search", search)
Stimulus.register("bucket", bucket)
Stimulus.register("core-search", core_search)
Stimulus.register("turbo-frame-history", turbo_frame_history)
Stimulus.register("search-select", search_select)
Stimulus.register("selectize", selectize)
Stimulus.register("redirect", redirect)
Stimulus.register("expandable-box", expandable_box)
Stimulus.register("flash", flash)
Stimulus.register("date-picker-range", date_picker_range)
Stimulus.register("file-input", file_input)

// import Flatpickr & register Flatpickr as a stimulus controller
import Flatpickr from 'stimulus-flatpickr' 
import "flatpickr/dist/flatpickr.css"
Stimulus.register('flatpickr', Flatpickr) 